import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faBars, faPhone, faClose, faCircle, faCheck, faChevronRight, faChevronLeft, faCommentDots, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookF, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

/* add icons to the library */
library.add(faBars, faPhone, faClose, faCircle, faCheck, faInstagram, faFacebookF, faTwitter, faWhatsapp, faChevronRight, faChevronLeft, faCommentDots, faPaperPlane)

import './styles/styles.css'

createApp(App)
    .use(store)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
