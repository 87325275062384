export default {
    path: '',
    name: 'page',
    component: () => import(/* webpackChunkName: "page" */'@/modules/page/layouts/PageViewLayout.vue'),
    children: [
        {
            path: '',
            name: 'home',
            component: () => import(/* webpackChunkName: "HomeView" */'@/modules/page/views/HomeView.vue')
        },
        {
            path: '/amenidades',
            name: 'amenidades',
            component: () => import(/* webpackChunkName: "GalleryView" */'@/modules/page/views/AmenitiesView.vue')
        },
        {
            path: '/nosotros',
            name: 'nosotros',
            component: () => import(/* webpackChunkName: "nosotros" */'@/modules/page/views/AboutView.vue')
        },
        {
            path: '/plantas-tipo',
            name: 'plantas-tipo',
            component: () => import(/* webpackChunkName: "BluePrints" */'@/modules/page/views/BluePrints.vue')
        },
        {
            path: '/contacto',
            name: 'contacto',
            component: () => import(/* webpackChunkName: "BluePrints" */'@/modules/page/views/ContactView.vue')
        },
    ]
}